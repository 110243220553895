body {
  background-color: #000;
  color: #0f0;
  font-family: 'Courier New', monospace;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #333;
  width: 100%; /* Add this line */
}

.logo {
  height: 50px;
}

nav a {
  margin: 0 10px;
  color: #0f0;
  text-decoration: none;
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: #0f0;
  text-align: center;
  padding: 10px;
}
